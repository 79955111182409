<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import helpers from '../../../helpers'

import {
  analyticsMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Usage",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    Multiselect
  },
  data() {
    return {
      title: '',
      items: [],
      
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      query: '',
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      isLocal: window.config.local ? true : false,
      dates:[],
      selectedDate: null,
      series: [{
              name: this.$t('usage.sessions'),
              data:[]
            }],
      seriesEmail: [{
        name: 'Emails',
        data:[]
      }],
      chartoptions: {
        chart: {
          height: 350,
          type: "area",
          sparkline: {
              enabled: false,
            },
          toolbar: {
            show: false,
          },
        },
        colors: ["#5e40bf"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        yaxis:{
          labels: {
            show:true
          }
        }          
      },
      totalSessionCount: 0,
      totalSessionAvailable: 200000,
      totalSessionPercentage: 0,
      sessions_status : '',

      totalEmailsCount: 0,
      totalEmailsAvailable: 700000,
      totalEmailsPercentage: 0,
      emails_status : '',
      

      
    };
  },
  mounted() {
    const self = this;

    this.chartoptions.xaxis = {
        labels: {
          formatter: function (valor) {
            return self.parseDateWithLocale(new Date(valor),{ month: 'short', day: 'numeric', timeZone: 'UTC'});
          }
        }
      }
    
      this.$refs.TrendsChart.updateOptions(this.chartoptions);
      this.generateDateList();
  },
  methods:{
    ...analyticsMethods,
    ...helpers,

    generateDateList() {
      const currentDate = new Date();
      const months = [this.$t('common.months.january'), this.$t('common.months.february'), this.$t('common.months.march'), this.$t('common.months.april'), this.$t('common.months.may'), this.$t('common.months.june'), this.$t('common.months.july'), this.$t('common.months.august'), this.$t('common.months.september'), this.$t('common.months.october'), this.$t('common.months.november'), this.$t('common.months.december')];

      for (let i = 0; i < 5; i++) {
        const month = (currentDate.getMonth() - i + 12) % 12;
        const year = currentDate.getFullYear() - (month > currentDate.getMonth() ? 1 : 0);

        const formattedDate = `${year}-${month + 1 < 10 ? '0' : ''}${month + 1}-01`;
        const formattedLabel = `${months[month]} ${year}`;

        this.dates.push({ id: formattedDate, label: formattedLabel });

        this.selectedDate = this.dates[0];
      }
    },
    formatDate(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    },

    onSelectedDateChanged(){
      //this.loadSessions(this.selectedDate.id);
      //this.loadEmails()
    },

    loadSessions(date){
      const query = `dateFrom=${date}`
      const loader = this.$loading.show()
      this.getTotalSessionsTimeline(query).then((res)=>{
        if(res.data){
          this.series[0].data = Array.from(res.data, x => [ new Date(x.session_date).getTime() , x.session_count]);
        this.series[0].data.sort((a, b) => a[0] - b[0]);

        this.$refs.TrendsChart.updateSeries(this.series,true);

        this.totalSessionCount = res.data.reduce((sum, obj) => sum +  parseInt(obj.session_count), 0);
        this.totalSessionPercentage = this.totalSessionCount/this.totalSessionAvailable * 100 > 100 ? `100%` : `${this.parsePercentage(this.totalSessionCount/this.totalSessionAvailable * 100)}%`

        if(this.totalSessionCount/this.totalSessionAvailable * 100 > 100){
          this.sessions_status = 'danger'
          this.chartoptions.colors[0] = '#f46a6a'
        }
        else if(this.totalSessionCount/this.totalSessionAvailable * 100 > 90){
          this.sessions_status = 'warning'
          this.chartoptions.colors[0] = '#f1b44c'
        }
        else{
          this.sessions_status = ''
          this.chartoptions.colors[0] = '#5e40bf'
        } 
        this.$refs.TrendsChart.updateOptions(this.chartoptions); 
        }
        
      }).finally(()=>{
        loader.hide()
      })
    },

    loadEmails(date){
      const query = `dateFrom=${date}`

      this.getTotalEmails(query).then((res)=>{

        this.seriesEmail[0].data = Array.from(res.data, x => [ new Date(x.date).getTime() , x.emails]);
        this.seriesEmail[0].data.sort((a, b) => a[0] - b[0]);

        this.$refs.EmailTrendsChart.updateSeries(this.seriesEmail,true);

        this.totalEmailsCount = res.data.reduce((sum, obj) => sum +  parseInt(obj.emails), 0);
        this.totalEmailsPercentage = this.totalEmailsCount/this.totalEmailsAvailable * 100 > 100 ? `100%` : `${this.parsePercentage(this.totalEmailsCount/this.totalEmailsAvailable * 100)}%`

        if(this.totalEmailsCount/this.totalEmailsAvailable * 100 > 100){
          this.emails_status = 'danger'
        }
        else if(this.totalEmailsCount/this.totalEmailsAvailable * 100 > 90){
          this.emails_status = 'warning'
        }
        else{
          this.emails_status = ''
        } 

        this.$refs.EmailTrendsChart.updateOptions(this.chartoptions);
      })
    }

  },
  watch: {
    selectedDate:{
      immediate: true,
      handler(newVal){
        if(newVal){
          this.loadSessions(newVal.id);
          this.loadEmails(newVal.id);
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-0">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{$t('usage.title')}}</h5>
            <p class="text-muted mb-0">{{$t('usage.subtitle')}}</p>
            <a class="small text-primary mb-0" href="https://academy.gopersonal.ai/guia-de-usuario/analytics/uso" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a>
          </div>
          <div class="text-end w-50">
            <div class="row">
              <div class="col">
              </div>
              <div class="col">
                <multiselect 
                  :options="dates"
                  :selectLabel="$t('common.select')"
                  :deselectLabel="$t('common.deselect')"
                  track-by="id" 
                  label="label"
                  :show-labels="false"
                  :multiple="false"
                  v-model="selectedDate"
                  @input="onSelectedDateChanged">
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="d-flex">
                <div class="d-flex flex-column w-100">
                  <h4 class="card-title me-2">{{$t('usage.sessions')}}</h4>
                  <p class="small mb-1">{{$t('usage.sessions_subtitle')}}</p>
                  <hr>
                  <h5 class="font-size-14">
                    {{`${totalSessionCount}/${totalSessionAvailable}`}} <span class="float-end">{{ `${totalSessionPercentage}` }}</span>
                  </h5>
                  <div class="progress animated-progess progress-sm">
                    <div
                      class="progress-bar"
                      :class="sessions_status == 'warning' ? 'bg-warning' : sessions_status == 'danger' ? 'bg-danger' : ''"
                      role="progressbar"
                      :style="{ width: totalSessionPercentage }"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <hr>
                  <apexchart
                    ref="TrendsChart"
                    class="apex-charts"
                    dir="ltr"
                    height="350"
                    :options="chartoptions"
                    :series="series.length > 0 ? series : []">
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12" >
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="d-flex">
                <div class="d-flex flex-column w-100">
                  <h4 class="card-title me-2">{{'Emails'}}</h4>
                  <p class="small mb-1">{{$t('usage.emails_subtitle')}}</p>
                  <hr>
                  <h5 class="font-size-14">
                    {{`${totalEmailsCount}/${totalEmailsAvailable}`}} <span class="float-end">{{ `${totalEmailsPercentage}` }}</span>
                  </h5>
                  <div class="progress animated-progess progress-sm">
                    <div
                      class="progress-bar"
                      :class="emails_status == 'warning' ? 'bg-warning' : emails_status == 'danger' ? 'bg-danger' : ''"
                      role="progressbar"
                      :style="{ width: totalEmailsPercentage }"
                      aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                  <hr>
                  <apexchart
                    ref="EmailTrendsChart"
                    class="apex-charts"
                    dir="ltr"
                    height="350"
                    :options="chartoptions"
                    :series="seriesEmail.length > 0 ? seriesEmail : []">
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</Layout>
</template>

<style scoped>
 .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

</style>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}
</style>
